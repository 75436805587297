
import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Icon from "@/components/reusable/Icon.vue";
import { getLink, getImage } from "@/utility/asset";
import AssetMenu from "@/components/asset/AssetMenu.vue";
import PartsEditTable from "@/components/asset/PartsEditTable.vue";
import { AssetModel, AssetModelRequest } from "@/models/asset";
import AssetService from "@/services/asset_service";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { AuthError, NotFoundError } from "@/services/error_service";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import MoveItem from "@/components/reusable/MoveItem.vue";
import { GlobalGetters, GlobalActions } from "@/store/modules/global/types";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { convertToSlug } from "@/utility/helpers";
import { filenameRegex } from "@/utility/asset";
import { ConflictError } from "@/services/error_service";

Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Wysiwyg,
    FormInput,
    Icon,
    AssetMenu,
    MoveItem,
    PartsEditTable,
  },
})
export default class AssetEditor extends Vue {
  protected showWarningModal = false;
  protected asset = {} as AssetModel;
  protected isNew = false;
  protected assetService = new AssetService();
  protected id = 0;
  protected deleteData: AssetModel[] = [];
  protected toast = false;
  protected messageHtml = "";
  protected className = "success";
  protected getLink = getLink;
  protected getImage = getImage;
  protected moveData: AssetModel[] = [];
  protected moveDestination = {} as AssetModel;
  protected showMoveModals = false;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddBusinessUnit))
  setBusinessUnit: any;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @Validations()
  validations() {
    return {
      asset: {
        filename: { required },
        display_name: { required },
      },
    };
  }

  created() {
    if (!this.$route.params.id) {
      this.isNew = true;
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      this.getSingleAsset();
    }
  }

  mounted() {
    this.showMoveModals = true;
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: AssetModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "asset" },
        { height: "auto", adaptive: true }
      );
    });
  }

  protected async sendMoveRequest(
    newParent: string,
    id: number,
    name: string,
    origin: AssetModel,
    final = false
  ): Promise<void> {
    try {
      await this.assetService.saveAsset(
        { filename: origin.filename, path: newParent },
        id
      );
      this.getSingleAsset();
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Asset <strong>${this.asset.display_name
          ? this.asset.display_name
          : this.asset.filename
        }</strong> has been moved.`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit(
          "showFail",
          `The move request for the following asset failed. Please try again.`,
          []
        );
      }
    }
    this.moveData = [];
  }

  protected setDestination(value: AssetModel) {
    this.moveDestination = value;
  }

  protected move() {
    this.moveData.push(this.asset);
    UIkit.modal(document.getElementById("move-modal") as HTMLElement).show();
  }

  protected openFileManager(): void {
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  protected validateFileType(event: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const file = (event.target as HTMLInputElement).files![0];
    const validTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!validTypes.includes(file.type)) {
      EventBus.$emit(
        "showNotification",
        `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
          <div class="uk-alert-danger">
            Invalid file type. Please upload a <strong>PNG, JPEG, or PDF</strong> file.
          </div>
        </div>`
      );
      (event.target as HTMLInputElement).value = "";
      return;
    }
    this.replaceFile();
  }

  protected replaceFile(): void {
    this.setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const file = (this.$refs.fileInput as HTMLInputElement).files![0];
    // overwrite readonly property "name" of file object with formatted text
    Object.defineProperty(file, "name", {
      value: filenameRegex(convertToSlug(file.name)),
      writable: true,
    });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let content;
      if (reader.result) {
        if ((reader.result as string).includes("data:application")) {
          content = (reader.result as string).replace(
            /^data:application\/[a-z]+;base64,/, // removing prepended info
            ""
          );
        } else {
          content = (reader.result as string).replace(
            /^data:image\/[a-z]+;base64,/, // removing prepended info
            ""
          );
        }
        this.postReplacement({
          filename: file.name,
          content: content,
          path: this.asset.path,
        });
      }
    };
  }

  protected async postReplacement(req: AssetModelRequest): Promise<void> {
    try {
      await this.assetService.saveAsset(req, this.id);
      EventBus.$emit(
        "showNotification",
        `<div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
          <div class="uk-alert-success">
            <strong><span>File has been uploaded successfully.</span> </strong>
          </div>
        </div>`
      );
      this.refreshPage();
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof ConflictError) {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
            <div class="uk-alert-danger">
              <strong><span>${req.filename} upload failed. Filename already exists - please rename and try again.</span> </strong>
            </div>
          </div>`
        );
      } else {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
            <div class="uk-alert-danger">
              <strong><span>${req.filename} upload failed.</span> </strong>
            </div>
          </div>`
        );
      }
      this.refreshPage();
    }
  }

  public refreshPage(): void {
    this.getSingleAsset();
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function () {
            next();
          },
          function () {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal",
    ];
    modals.forEach((selector) => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }

  protected updateAssociatedItems(data: any) {
    this.asset.associatedItems = data;
  }

  protected cancel(): void {
    this.$router.go(-1);
  }

  protected closeToast(): void {
    this.toast = false;
  }

  protected showToast(message: string, className: string): void {
    this.messageHtml = message;
    this.className = className;
    this.toast = true;
  }

  protected save(): void {
    this.$v.asset.$touch();
    if (!this.$v.$invalid) {
      this.saveExisting();
    }
  }

  protected saveKeypress(): void {
    this.save();
  }
  protected get website(): string {
    if (this.businessUnit == 'bmh') {
      return process.env.VUE_APP_C5_URL;
    } else {
      return process.env.VUE_APP_WORKMASTER_URL;
    }
  }

  protected async getSingleAsset(): Promise<void> {
    try {
      this.setLoading(true);
      const res: AssetModel = await this.assetService.getSingleAsset(this.id);
      this.asset = res;
      if (
        this.asset.associatedItems.length > 0 &&
        this.businessUnit !== "workmaster"
      ) {
        this.setBusinessUnit("workmaster");
      }
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) },
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    try {
      await this.assetService.deleteAsset(id);
      this.$router.push({
        path: "/asset",
        query: {
          deleted: encodeURI(`${this.asset.filename}`),
          type: "asset",
        },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  // protected async postNew(): Promise<void> {
  //   try {
  //     await this.assetService.createNewAsset(this.asset);
  //     this.showToast(
  //       `Asset <strong>${this.asset.filename}</strong> has been ${this.action}. <a href="${this.website}/assets/${this.asset.filename}" target="_blank">View Live</a>`,
  //       "success"
  //     );
  //   } catch (err) {
  //     if (err instanceof AuthError) {
  //       AuthError.logout();
  //     } else {
  //       EventBus.$emit("showError", err.message);
  //     }
  //   }
  //
  // }

  protected async saveExisting(): Promise<void> {
    try {
      await this.assetService.saveAsset(this.asset, this.asset.id as number);
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.getSingleAsset();
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Product <strong>${this.asset.filename}</strong> has been saved.`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  get action(): string {
    let action = "saved";
    if (this.isNew) {
      action = "created";
    }
    return action;
  }
}
