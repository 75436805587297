
import { Component, Prop } from "vue-property-decorator";
import RowMenu from "@/components/reusable/menu/RowMenu";
/**
 * All menu components use the prop "table" to decide what children components to render.
 *
 * Table === true means the menu is present in the parent table
 * Table === false means the menu is present on the edit screen (usually means hiding certain components, like <Edit>)
 */

@Component
export default class AssetMenu extends RowMenu {
  @Prop() id!: number;
  @Prop() display_name!: string;
  @Prop() file_type!: string;
  @Prop() filename!: string;
  @Prop({ default: true }) table!: boolean;

  get name(): string {
    return this.display_name ? this.display_name : this.filename;
  }

  protected rename(): void {
    this.$emit("renameFolder", this.id);
  }

  protected moveItem(): void {
    this.$emit("move");
  }

  protected replaceItem(): void {
    this.$emit("replace");
  }
}
