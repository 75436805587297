
import { Component, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Icon from "@/components/reusable/Icon.vue";
import EditTableMenu from "@/components/reusable/editable_table/EditTableMenu.vue";
import EditTable from "@/components/reusable/editable_table/EditTable.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import PartService from "@/services/part_service";
import { PartSchematic } from  "@/models/part_schematic";
import { PartModel } from "@/models/part";
import { APIResponse } from "@/models/api_res";
import { AuthError } from "@/services/error_service";
import { EventBus } from "@/events/index";
import { GlobalGetters } from "@/store/modules/global/types";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";

@Component({
  components: {
    draggable,
    Icon,
    EditTableMenu,
    FormInput,
    Autocomplete
  }
})
export default class PartsEditTable extends EditTable {
  @Prop({
    default: () => [] as PartSchematic[]
  })
  data!: PartSchematic[];

  @Prop({ default: false }) disableDrag!: boolean;
  
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  protected partsList: PartSchematic[] = [];
  protected partService = new PartService();

  @Watch("tableData", { deep: true, immediate: false })
  protected onUpdate(): void {
    this.$emit("update", this.tableData);
  }
  mounted() {
    if (this.data.length < 1) {
      this.tableData = [];
    } else {
      this.tableData = [...this.data];
      this.updateEditTags();
    }
  }

  // beforeDestroy() {
  // }

  protected updateEditTags(): void {
    this.isEditTags = this.tableData.map(row => {
      return { id: row.id, isEdit: false };
    });
  }

  /** Returns whether input is editable */
  public editInput(rowId: number): boolean | null {
    const row: any[] = this.isEditTags.filter(row => {
      if (row.id === rowId) {
        return row; // return matching item in isEditTags (row will = { id: number, isEdit: boolean} )
      }
    });
    if (row[0]) {
      // if row exists, return isEdit value (boolean)
      return row[0].isEdit;
    } else {
      return null; // no match, no change
    }
  }

  protected async updatePartItemNumberInput(item: any, rowId: number) {
    const part = await this.getSinglePart(item.id as number); //get attribute from object sent from <Autocomplete> component. this is the item the user clicked on in the dropdown
    this.tableData = this.tableData.map(row => {
      // find matching row and update with data returned from api. do not update row.value, this is user entered
      if (row.id === rowId) {
        row = {
          ...row,
          id: part.id,
          item_number: part.item_number,
          description: part.description,
        };
      }
      return row;
    });
    this.isEditTags.forEach(item => {
      if (item.id === rowId) {
        item.id = part.id;
        item.isEdit = true;
      }
    });
  }

  protected toggleEdit(rowId: number, boolean = true) {
    this.isEditTags.forEach(row => {
      if (row.id === rowId) {
        row.isEdit = boolean;
      }
    });
  }

  protected async getParts(optionsObject: any): Promise<void> {
    try {
      const res: APIResponse = await this.partService.getParts(optionsObject);
      this.partsList = res.results;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async getSinglePart(id: number): Promise<PartModel> {
    let part = {} as PartModel;
    try {
      const res: PartModel = await this.partService.getSinglePart(id);
      part = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return part;
  }

  protected addRow(): void {
    const id = this.randomStringId();
    const newObj: any = { id: id, isEdit: true };
    this.isEditTags.push(newObj);
    const emptyRow: PartSchematic = {
      item_number: "",
      diagram_number: "",
      description: "",
      id,
      quantity_required: "",
    };
    this.tableData.push(emptyRow);
  }

  protected autoCompleteFetchParts(value: any): void {
    this.getParts({ q: value, bu: this.businessUnit });
  }

  protected handleKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
